<template>
  <div class="aiz-topbar px-15px px-lg-25px d-flex align-items-stretch justify-content-between">
    <div class="d-flex">
      <div class="aiz-topbar-nav-toggler d-flex align-items-center justify-content-start mr-2 mr-md-3 ml-0" data-toggle="aiz-mobile-nav">
        <button class="aiz-mobile-toggler" @click="changeMenu">
          <span></span>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-stretch flex-grow-xl-1 right-bar">
      <div class="d-flex justify-content-around align-items-center align-items-stretch">
        <div class="d-flex justify-content-around align-items-center align-items-stretch">
          <div class="aiz-topbar-item">
            <div class="d-flex align-items-center">
              <a class="btn btn-icon btn-circle btn-light" :title="$t('shou-ye')" @click="toPath('index')">
                <i class="las la-globe"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-around align-items-center align-items-stretch ml-3">
          <div class="aiz-topbar-item">
            <div class="d-flex align-items-center">
              <a class="btn btn-icon btn-circle btn-light"  title="POS">
                <i class="las la-print"></i>
              </a>
            </div>
          </div>
        </div> -->

        <div class="d-flex justify-content-around align-items-center align-items-stretch ml-3" style="width: auto">
          <div class="aiz-topbar-item">
            <div class="d-flex align-items-center">
            <span class="btn btn-circle btn-light customer_service_link flex-center" @click="toChat" :title="$t('ke-fu-lian-jie')">
              {{ $t('ke-fu-lian-jie-0') }}
              <span class="kefu-tag flex-center" v-if="showMsgTag"></span>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around align-items-center align-items-stretch">

        <div class="aiz-topbar-item ml-2">
          <div class="align-items-stretch d-flex dropdown">
            <a class="dropdown-toggle no-arrow" data-toggle="dropdown" href="javascript:void(0);" role="button" aria-haspopup="false" aria-expanded="false" @click.stop="changeNoticeShow">
              <span class="btn btn-icon p-0 d-flex justify-content-center align-items-center">
                <span class="d-flex align-items-center position-relative">
                  <i class="las la-bell fs-24"></i>
                  <span class="badge badge-sm badge-dot badge-circle badge-primary position-absolute absolute-top-right" v-if="noticeList.length > 0"></span>
                </span>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg py-0" :class="showNotice ? 'show' : ''">
              <div class="p-3 bg-light border-bottom">
                <h6 class="mb-0">{{ $t('tong-zhi') }}</h6>
              </div>
              <div class="px-3 c-scrollbar-light overflow-auto " style="max-height:300px;">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items- py-3" v-for="(item, i) in noticeList" :key="i">
                    <div class="media text-inherit">
                      <div class="media-body">
                        <p class="mb-1 text-truncate-2">
                          {{item.Content}}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="py-4 text-center fs-16">
                      {{ $t('wei-zhao-dao-tong-zhi') }} </div>
                  </li>
                </ul>
              </div>
              <div class="text-center border-top">
                <a  class="text-reset d-block py-2">
                    {{ $t('cha-kan-suo-you-tong-zhi') }} </a>
              </div>
            </div>
          </div>
        </div>


        <div class="aiz-topbar-item ml-2">
          <a class="dropdown-toggle no-arrow" href="javascript:void(0);" >{{ $t('bao-zheng-jin-0') }} ${{depositMoney}}</a>
        </div>

        <div class="aiz-topbar-item ml-2">

          <Popover v-model="showLangMenu" trigger="click"  class="align-items-stretch d-flex">
            <div class="align-items-stretch d-flex dropdown ">

              <ul class="show" >
                <li v-for="(item, i) in langList" :key="i">
                  <a class="dropdown-item " :class="lang == item.key ? 'active' : ''" @click.stop="chooseLang(item)">
                    <img :src="item.url" class="mr-2 lang-img">
                    <span class="language">{{item.name }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <template #reference>
              <a class="dropdown-toggle no-arrow" data-toggle="dropdown" href="javascript:void(0);" role="button" >
                <!-- @click.stop="changeLangShow" -->
                <span class="btn btn-icon">
                  <img :src="flagUrl" height="11">
                </span>
              </a>
            </template>
          </Popover>
        </div>

        <div class="aiz-topbar-item ml-2">

          <Popover v-model="showAction" trigger="click"  class="align-items-stretch d-flex">
            <div class="align-items-stretch d-flex dropdown" >
              <div class=" dropdown-menu-md menu-action" >
                <a  class="dropdown-item" @click="toPath('sellerUserInfo')">
                  <i class="las la-user-circle"></i>
                  <span>{{ $t('yong-hu-xin-xi') }}</span>
                </a>

                <a class="dropdown-item" @click="logout">
                  <i class="las la-sign-out-alt"></i>
                  <span>{{ $t('tui-chu') }}</span>
                </a>
              </div>
            </div>
            <template #reference>
              <a class="dropdown-toggle no-arrow text-dark" data-toggle="dropdown" href="javascript:void(0);" >
                <span class="d-flex align-items-center">
                  <span class="avatar avatar-sm mr-md-2">
                    <img :src="userInfo.Photo ? `${userInfo.Photo}` : headerUrl" >
                  </span>
                  <span class="d-none d-md-block">
                    <span class="d-block fw-500">{{userInfo.FullName}}</span>
                    <span class="d-block small opacity-60">seller</span>
                  </span>
                </span>
              </a>
            </template>
          </Popover>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { getDefaultLanguage, resetPrice } from '@/utils/common';
import { removeToken, removeUserType } from '@/utils/auth';
import { getNoticeList } from '@/api/index';
import { conversationList } from '@/api/shop'
import { Popover  } from 'vant'
import {userInfo, wallet} from "@/api/user";
export default {
  name: 'tabbar',
  components: {
    Popover
  },
  props:{

  },
  data() {
    return {
      headerUrl: require('../../assets/imgs/avatar-place.png'),
      flagUrl: '',
      showSearchMenu: false,
      routeName: '',
      langList: [],
      lang: '',
      depositMoney: '0.00',
      langArrBak: [
        {
          url: require('../../assets/imgs/lang/zh.png'),
          name: '简体中文',
          key: 'zh-CN',
        },
        {
          url: require('../../assets/imgs/lang/zh.png'),
          name: '繁体中文',
          key: 'zh-TW',
        },
        {
          url: require('../../assets/imgs/lang/en.png'),
          name: 'English',
          key: 'en',
        },
        // 哈萨克斯坦语
        {
          url: require('../../assets/imgs/lang/kk.png'),
          name: 'Русский',
          key: 'kk',
        },
        //   西班牙语
        {
          url: require('../../assets/imgs/lang/es.png'),
          name: 'Español',
          key: 'es',
        },
        // 法语
        {
          url: require('../../assets/imgs/lang/fr.png'),
          name: 'Français',
          key: 'fr',
        },
        // 德语
        {
          url: require('../../assets/imgs/lang/de.png'),
          name: 'Deutsch',
          key: 'de',
        },
        // 意大利语
        {
          url: require('../../assets/imgs/lang/it.png'),
          name: 'Italiano',
          key: 'it',
        },
        // 葡萄牙语
        {
          url: require('../../assets/imgs/lang/pt.png'),
          name: 'Português',
          key: 'pt',
        },
        // 俄语
        {
          url: require('../../assets/imgs/lang/ru.png'),
          name: 'Pусский',
          key: 'ru',
        },
        //   土耳其语
        {
          url: require('../../assets/imgs/lang/tr.png'),
          name: 'Türkçe',
          key: 'tr',
        },
        // 韩语
        {
          url: require('../../assets/imgs/lang/ko.png'),
          name: '한국어',
          key: 'ko',
        },
        // 日语
        {
          url: require('../../assets/imgs/lang/ja.png'),
          name: '日本語',
          key: 'ja',
        },
        // 越南语
        {
          url: require('../../assets/imgs/lang/vi.png'),
          name: 'Tiếng Việt',
          key: 'vi',
        },
        // 印尼语
        {
          url: require('../../assets/imgs/lang/id.png'),
          name: 'Bahasa Indonesia',
          key: 'id',
        },
        // 马来语
        {
          url: require('../../assets/imgs/lang/ms.png'),
          name: 'Bahasa Melayu',
          key: 'ms',
        },
        // 泰语
        {
          url: require('../../assets/imgs/lang/th.png'),
          name: 'ภาษาไทย',
          key: 'th',
        },
        // 阿拉伯语
        {
          url: require('../../assets/imgs/lang/ar.png'),
          name: 'العربية',
          key: 'ar',
        },
        // 希伯来语
        {
          url: require('../../assets/imgs/lang/he.png'),
          name: 'עברית',
          key: 'he',
        },
        // 印地语
        {
          url: require('../../assets/imgs/lang/hi.png'),
          name: 'हिन्दी',
          key: 'hi'
        },
        // 荷兰语，波斯语，瑞典语，乌克兰语，罗马利亚语，阿拉伯语，乌尔都语，希腊语，匈牙利语，波兰语，丹麦语，保加利亚语，俄语，芬兰语，捷克语，泰语
        // 波斯语
        {
          url: require('../../assets/imgs/lang/fa.png'),
          name: 'فارسی',
          key: 'fa',
        },
        // 乌克兰语
        {
          url: require('../../assets/imgs/lang/uk.png'),
          name: 'Українська',
          key: 'uk',
        },
        // 乌尔都语
        {
          url: require('../../assets/imgs/lang/ur.png'),
          name: 'اردو',
          key: 'ur',
        },
        // 希腊语
        {
          url: require('../../assets/imgs/lang/el.png'),
          name: 'Ελληνικά',
          key: 'el',
        },
        // 匈牙利语
        {
          url: require('../../assets/imgs/lang/hu.png'),
          name: 'Magyar',
          key: 'hu',
        },
        // 波兰语
        {
          url: require('../../assets/imgs/lang/pl.png'),
          name: 'Polski',
          key: 'pl',
        },
        // 丹麦语
        {
          url: require('../../assets/imgs/lang/da.png'),
          name: 'Dansk',
          key: 'da',
        },
        // 保加利亚语
        {
          url: require('../../assets/imgs/lang/bg.png'),
          name: 'Български',
          key: 'bg',
        },
        // 俄语，芬兰语，捷克语，泰语
        // 芬兰语
        {
          url: require('../../assets/imgs/lang/fi.png'),
          name: 'Suomi',
          key: 'fi',
        },
        // 捷克语
        {
          url: require('../../assets/imgs/lang/cs.png'),
          name: 'Čeština',
          key: 'cs',
        },
        // 荷兰语
        {
          url: require('../../assets/imgs/lang/nl.png'),
          name: 'Nederlands',
          key: 'nl',
        },
        // 瑞典语
        {
          url: require('../../assets/imgs/lang/sv.png'),
          name: 'Svenska',
          key: 'sv',
        },
        // 罗马利亚语
        {
          url: require('../../assets/imgs/lang/ro.png'),
          name: 'Română',
          key: 'ro',
        },
      ],
      noticeList: [],
      showNotice: false,
      showLang: false,
      showAction: false,
      showLangMenu: false,
      showMsgTag: false,
      timer: ''
    }
  },
  computed: {
		bodyClick() {
			return this.$store.state.bodyClick
		},
		userInfo() {
			return this.$store.state.userInfo
		},
  },
  watch: {
		bodyClick() {
			this.clearClick()
		},
    userInfo(val) {
      if (val.UserAccount) {
        this.depositMoney =  parseFloat(this.userInfo.UserAccount.Deposit).toFixed(2)
      }
    }
	},
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    let locale = localStorage.getItem('locale')
    const defaultLanguage = getDefaultLanguage()
    this.lang = locale ? locale : defaultLanguage

    this.langList = this.langArrBak

    let index = this.langList.findIndex(v => {
      return v.key == this.lang
    })
    if (index === -1) {
      index = 0
    }
    this.flagUrl = this.langList[index].url
    this.langName = this.langList[index].name
    this.lang = this.langList[index].key
    console.log(111, this.lang)

    this.i18n.locale = this.lang
    this.$store.state.language = this.lang

    if (this.userInfo.UserAccount) {
      this.depositMoney =  parseFloat(this.userInfo.UserAccount.Deposit).toFixed(2)
    }

    // this.initNotice()
    this.getDepositMoney()
    //定时查询未读消息
    this.initMsg()
  },
  methods: {
    resetPrice,
    changeMenu() {
      this.$store.state.showMenu = !this.$store.state.showMenu
    },
    getDepositMoney(){
      wallet().then(res => {
        let data = res.data.Items[0]
        if (res.code == 0) {
          this.depositMoney =  parseFloat(data.Deposit).toFixed(2)
        }
      })
    },
    initMsg() {
      this.timer = setInterval(() => {
        let form = new FormData()
        form.append('pageSize', 30)
        conversationList(form).then(res => {
          let list = res.data.Items
          this.showMsgTag = list.filter(v => {
            return v.UnreadMessageCount > 0
          }).length > 0
        })
      }, 10000)
    },
    initNotice() {
      getNoticeList().then(res => {
        this.noticeList = res.data.Items
      })
    },
    changeNoticeShow() {
      this.showNotice = !this.showNotice
      this.showLang = false
      // this.showAction = false
    },
    // changeShowAction() {
    //   this.showAction = !this.showAction
    //   this.showLang = false
    //   this.showNotice = false
    // },
    changeLangShow() {
      this.showLang = !this.showLang
      this.showNotice = false
      // this.showAction = false
    },
    chooseLang(data) {
      this.lang = data.key
      this.flagUrl = data.url
      this.showLang = false
      localStorage.setItem('locale', data.key)
      localStorage.setItem('localeName', data.name)
      this.i18n.locale = data.key
      this.showLangMenu = false
      this.$store.state.language = data.key
      window.location.reload()
    },
    clearClick() {
      this.showLang = false
      this.showNotice = false
      // this.showAction = false
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toChat() {
      this.$router.push({
        name: 'message'
      })
    },
    logout() {
      removeToken()
      removeUserType()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'index'
      })
    }
  }
}
</script>
<style lang="less" >
.kefu-tag {
  width: 8PX;
  height: 8PX;
  margin-left: 8PX;
  font-size: 10PX;
  color: #fff;
  background: #fa3f3f;
  border-radius: 50%;
}
</style>